import { Grid } from '@mui/material'
import React from 'react'
import AdjustIcon from '@mui/icons-material/Adjust';
import { useNavigate } from 'react-router-dom';

const Ordercart = () => {
  const navigate= useNavigate();
  return (
    <div onClick={()=>navigate("/order/detial")} className='mt-10 p-5  shadow-md hover:shadow-2xl   shadow-black'>
      <Grid  container spacing={2}  sx={{justifyContent:"space-between"}}>
        <Grid item sx={6}>
            <div className='flex cursor-pointer'>
                <img  className='h-full w-[5rem] object-cover object-center' src="./copper1.jpg" alt="" />
                <div className='ml-5 '>
                    <p>
                       copper bree glass
                    </p>
                  
                </div>
            </div>

        </Grid>
        <Grid item xs={2}>
            <p> Rs299</p>
        </Grid>
        <Grid item xs={4}>
           { true && <p> 
            <AdjustIcon sx={{h:"15rem",w:"15rem"}} className='text-green-600 m2 text-sm'/>
                <span>
                    devlered on dec 05
                </span> </p>}
                {false && <p> <span> Expected Devlery</span></p>}
        </Grid>
      </Grid>
    </div>
  )
}

export default Ordercart
