import { Box, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import AddressCart from '../AddressCart/AddressCart'


const Delevery = () => {

   const handleSubmit=(e)=>{
      // e.preventDefault();
      console.log("here")
    const data =new FormData(e.currentTarget);
    const address={
      firstName:data.get("FirstName")
  
    }
    console.log(`here is ${address}`)

   }
    return (
        <div>
            <Grid container spacing={4}>
                <Grid xs={12} lg={5} className='border rounded-md mt-9 shadow-md h-[30.5rem] overflow-scroll'>
                    <div className='p-5 py-7 border-b cursor-pointer'>
                        <AddressCart />
                        <Button sx={{ mt: 2, bgcolor: "RGB(145,85,253)" }} size='large' variant='contained'> Deliver Here</Button>
                    </div>
                </Grid>
 <Grid item xs={12} lg={7}>
    <Box className="border rounded-lg shadow-md p-5">
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='firstname '
                   name='firstname '
                   label="firstname"
                   fullWidth autoComplete='given-name'
                   />
                </Grid>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='Lastname '
                   name='Lastname '
                   label="Lastname"
                   fullWidth autoComplete='given-name'
                   />
                </Grid>
                <Grid item xs={12}>
                   <TextField 
                   required
                   id='address'
                   name='address '
                   label="Address"
                   fullWidth autoComplete='given-name'
                   multiline rows={4}
                   />
                </Grid>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='City '
                   name='City '
                   label="City"
                   fullWidth 
                   />
                </Grid>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='State/Provinces/Region'
                   name='State/Provinces/Region'
                   label="State/Provinces/Region"
                   fullWidth autoComplete='given-name'
                   />
                </Grid>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='Zip/Postal Code'
                   name='Zip/Postal Code'
                   label="Zip/Postal Code"
                   fullWidth autoComplete='shipping postal-code'
                   />
                </Grid>
                <Grid item xs={12} sm={6}>
                   <TextField 
                   required
                   id='Phone Number'
                   name='Phone Number'
                   label="Phone Number"
                   fullWidth
                    autoComplete='given-name'
                   />
                </Grid>
            </Grid>
            
        </form>
        <Button 
        sx={{ mt: 5, bgcolor: "RGB(145,85,253)", py:1.5 }} 
        size='large'  variant='contained'
        type="submit"
        > Deliver Here</Button> 
    </Box>

 </Grid>
            </Grid>
        </div>
    )
}

export default Delevery
