 export const navigation = {
    categories: [
      {
        id: 'COPER',
        name: 'COPER',
        featured: [
          {
            name: 'New Arrivals copper item',
          to : '#',
            imageSrc: 'https://shivshaktiarts.in/cdn/shop/products/1copy_a7d2291d-beaa-464b-8574-e9bc20743bf2.jpg?v=1614418111',
            imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
          },
          {
            name: 'copper items',
            href: '#',
            imageSrc: 'https://i0.wp.com/sunecotechventures.com/wp-content/uploads/2023/02/1y-1.jpg?fit=600%2C600&ssl=1?v=1677115462 ',
            imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
          },
        ],
        sections: [
          {
            id: 'DRINKWARE',
            name: 'DRINKWARE',
            items: [
              { name: 'Copper Bottle', href: '/copper' },
              { name: 'Copper Pitchers', href: '#' },
              { name: 'Copper Tumblers', href: '#' },
              { name: 'Copper Combo Sets', href: '#' },
              { name: 'Copper Water pot', href: '#' },
              { name: 'Copper Bed Jar', href: '#' },
              { name: 'Copper Corporate ', href: '#' },
  
            ],
          },
          {
            id: 'BARWARE',
            name: 'BARWARE',
            items: [
              { name: 'Copper Moscow ', href: '#' },
              { name: 'Copper Moscow Mule Mugs With Coasters', href: '#' },
              { name: 'Copper Barware Essentials', href: '#' },
              { name: 'copper champagne glasses flutes', href: '#' },
  
            ],
          },
          {
            id: 'COPPER KITCHEN WARE',
            name: 'COPPER KITCHEN WARE',
            items: [
              { name: 'Copper & Steel Copper Handi', href: '#' },
              { name: 'Copper & Steel Copper Kadhai Wok', href: '#' },
              { name: 'Copper Fry/Serving Pans', href: '#' },
              { name: 'Copper Cookware', href: '#' },
              { name: 'Copper Tea Pots/Containers', href: '#' },
              { name: 'Steel Copper Chafing Dishes', href: '#' },
  
            ],
          },
        ],
      },
      {
        id: 'Brass Ware',
        name: 'Brass Ware',
        featured: [
          {
            name: 'New Arrivals',
            href: '#',
            imageSrc: 'https://m.media-amazon.com/images/I/91oqIZ+qxFL._AC_UF894,1000_QL80_.jpg',
            imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
          },
          {
            name: 'Artwork Tees',
            href: '#',
            imageSrc: 'https://api.gharpedia.com/wp-content/uploads/2018/08/0311080001-01-Brass-Utensils.jpg',
            imageAlt:
              'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
          },
        ],
        sections: [
          {
            id: 'BRASSWARE',
            name: 'BRASSWARE',
            items: [
              { name: 'Brass Platters', href: '#' },
              { name: 'Brass Serveware', href: '#' },
              { name: 'Brass Cutlery/Flatwares', href: '#' },
              { name: 'Brass Plates/Thali', href: '#' },
              { name: 'Brass Bowl', href: '#' },
              { name: 'Brass Dinner Set', href: '#' },
              { name: 'Brass Tumbler', href: '#' },
            ],
          },
  
          {
            id: 'BRASS POOJA SPIRITUAL',
            name: 'BRASS POOJA SPIRITUAL',
            items: [
              { name: 'Brass Pooja Thali', href: '#' },
              { name: 'Brass Diya', href: '#' },
              { name: 'Brass Bell', href: '#' },
              { name: 'Brass Tortoise', href: '#' },
              { name: 'Brass Laddu Gopal', href: '#' },
              { name: 'Brass Chains', href: '#' },
            ],
          },
        ],
      },
      {
        id: ' Steel Ware',
        name: ' Steel Ware',
        featured: [
  
        ],
        sections: [
          {
            id: 'STEEL DRINK WARE',
            name: 'STEEL DRINK WARE',
            items: [
              { name: 'Bronze Drinkwares', href: '#' },
              { name: 'Bronze Bowls', href: '#' },
              { name: 'Bronze Dinner Sets', href: '#' },
  
              { name: 'Bronze Serveware', href: '#' },
              { name: 'Bronze Plates/Thali', href: '#' },
            ],
          },
  
        ],
      },
      {
        id: '  Bronze ware',
        name: '  Bronze ware',
        featured: [
          {
            name: 'New Arrivals',
            href: '#',
            imageSrc: 'https://www.apnagharmart.com/Image/Blogs/202110595155Stainless%20Steel%20Utensils%20in%20India.jpg',
            imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
          },
          {
            name: 'Artwork Tees',
            href: '#',
            imageSrc: 'https://img3.exportersindia.com/product_images/bc-full/2020/7/984738/stainless-steel-utensils-1593775791-5507811.jpeg',
            imageAlt:
              'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
          },
        ],
        sections: [
          {
            id: 'STEEL DRINK WARE',
            name: 'STEEL DRINK WARE',
            items: [
              { name: 'Steel Bottles', href: '#' },
              { name: 'Steel Pitchers', href: '#' },
              { name: 'Steel Tumblers', href: '#' },
  
            ],
          },
          {
            id: 'STEEL TABLEWARES & SERVE WARES',
            name: 'STEEL TABLEWARES & SERVE WARES',
            items: [
              { name: 'Steel Kadhai Kadai', href: '#' },
              { name: 'Steel Handi and Serving Bowls', href: '#' },
              { name: 'Steel Fry & Serving Pan', href: '#' },
              { name: 'Steel Bowls & Pots', href: '#' },
              { name: 'Steel Thali/Dinner Sets', href: '#' },
              { name: 'Steel Tea Pots & Containers', href: '#' },
  
            ],
          },
          {
            id: 'STEEL BARWARE',
            name: 'STEEL BARWARE',
            items: [
              { name: 'Steel Barware Essentials', href: '#' },
              { name: 'Steel Tableware Essentials', href: '#' },
  
            ],
          },
        ],
      },
    ],
  
    pages: [
      { name: 'Company', href: '#' },
      { name: 'Stores', href: '#' },
    ],
  }