import React, { useState } from 'react';
import Homecard from '../HomeCard/Homecard';
import AliceCarousel from 'react-alice-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { electonic_item } from '../../../Data/electronic_items';

const Homesection = ({data,SectionName}) => {
  const responsive = {
    0: { items: 1.2 },
    720: { items: 3 },
    1024: { items: 3.5 },
    1324:{items:5.5}
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const slideNext = () => {
    if (activeIndex < electonic_item.length -5) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const syncActiveIndex = ({ item }) => {
    setActiveIndex(item);
  };

  const items = electonic_item.slice(activeIndex, activeIndex +5).map((item, index) => (
    <Homecard product={item} key={index} />
  ));
  

  return (
    <div className='border'>
      <h2 className='text-2xl font-extrabold text-gray-800 py-5 ml-10 '>{SectionName}</h2>
      <div className='relative p-5'>
        <AliceCarousel
          items={items}
          disableButtonsControls
          autoPlayInterval={1000}
          infinite
          responsive={responsive}
          disableDotsControls
          // onSlideChanged={syncActiveIndex}
          // activeIndex={activeIndex}
        />
        
        {activeIndex > 0 && (
          <Button
            variant="contained"
            onClick={slidePrev}
            className="z-50"
            style={{ position: "absolute", top: "8rem", left: "0rem" }}
            color='primary'
            aria-label='slidePrev'
          >
            <ArrowBackIosNewIcon />
          </Button>
        )}

        {activeIndex < electonic_item.length -6 && (
          <Button
            variant="contained"
            onClick={slideNext}
            className="z-50"
            style={{ position: "absolute", top: "8rem", right: "2rem" }}
            color='primary'
            aria-label='slideNext'
          >
            <ArrowForwardIosIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Homesection;
