export const Maincarouseldata =[
    {
        image :"./01.png",
        path :"women/sari/black"
    },
    {
        image :"./02.png",
        path :"women/sari/black"
    },
    {
        image :"./03.png",
        path :"women/sari/black"
    },
    {
        image :"./04.png",
        path :"women/sari/black"
    },
    {
        image :"./05.png",
        path :"women/sari/black"
    },
    {
        image :"./06.png",
        path :"women/sari/black"
    },
    {
        image :"./07.png",
        path :"women/sari/black"
    },
    {
        image :"./08.png",
        path :"women/sari/black",
       
    }

]