import React from 'react'
import "./product.css"
import { useNavigate } from 'react-router-dom'

const ProductCard = ({product}) => {
    const navigate=useNavigate();
  return (
    <div onClick={()=>navigate("/productDetial")} className='productcard w-[15rem] m-3 transition-all cursor-pointer'>
        <div className='h[20rem]'>
            <img className=' h-96 w-50  object-scale-down  object-center ' src={product.image}
             alt="" />
        </div>
    <div className='textPart bg-white p-3'>
        <div>
            <p className='font-bold opacity-60'>{product.brand}</p>
            <p>{product.title}</p>
        </div>
        <div className='flex items-center space-x-2'>
            <p className='font-semibold'>{product.price}</p>
            <p className='line-through opacity-50'>{product.discountprice}</p>
            <p className='text-green-600 font-semibold'>{product.discountpercentage}% off</p>
        </div>
    </div>
    </div>
  )
}

export default ProductCard
