
import { useState } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react'
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { Copper_item } from '../../../Data/Copper_items';
import Homesection from '../Homesection/Homesection';
import Homesection2 from '../Homesection/Homesection2';
import { useNavigate } from 'react-router-dom';

const product = {
name:"Steel Ware",
  price: '$192',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Copper_items', href: '#' },
    { id: 2, name: 'Brass Ware', href: '#' },
  ],
  images: [
    {
      src: './copper1.jpg',
      alt: 'Two each of gray, white, and black shirts laying flat.',
      "title": "Brass Designer Beer Glass Tumbler with 2 Jigger Shot Glasses, Best for Beer...",
      "discountprice": "499",
      "price": "Rs.2,090",
      "discountpercentage": "66 %Of",
    },
    {
      src: './cooper2.jpg',
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: './copper3.jpg',
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: './copper4.jpg',
      alt: 'Model wearing plain white basic tee.',
    },
  ],
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],
  sizes: [
    { name: 'XXS', inStock: false },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: true },
    { name: '2XL', inStock: true },
    { name: '3XL', inStock: true },
  ],
  description:
    'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Productd() {
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const [selectedSize, setSelectedSize] = useState(product.sizes[2])
   const navigate =useNavigate();

  

  return (
    <div className="bg-white">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav>
 <section className='grid grid-cols-1 lg:grid-cols-2  gap-x-8 gap-y-10 px-4 pt-10'>

 
        {/* Image gallery */}
        <div className="flex flex-col items-center">
          <div className="overflow-hidden rounded-lg max-w[30rem] max-h[5rem]">
            <img
              src={product.images[0].src}
              alt={product.images[0].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="flex flex-wrap space-x-5 justify-center">
           { product.images.map((item) => <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg max-w-[5rem] max-h[5rem] mt-4">
              <img
                src={item.src}
                alt={item.alt}
                className="h-full w-full object-cover object-center"
              />
            </div>) }
            
          </div>
         
        </div>

        {/* Product info */}
         <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8
         lg:pb-24">
          <div className="lg:col-span-2 ">
          { product.images.map((item) => <div className=''>
            <h1 className="text-lg lg:text-xl font-sembold text-justify
             text-gray-900">{item.title}</h1>
             </div>) }
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
           {/* Product info */}
        { product.images.map((item) =>   <div className=' flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6'>
            <p className="font-semibold">{item.price}</p>
            <p className='line-through opacity-50'>{item.discountprice}</p>
            
            <p className='text-green-600 font-semiblod'>{item.discountpercentage}</p>
            
            </div>)}

            {/* Reviews */}
            <div >
                <div className='flex items-center space-x-3'>
            <Rating name="read-only" value={2.5} readOnly />
            <p className='opacity-50 text-sm'>567898 Ratings</p>
            <p className='ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500'>3456 Reviews</p>
            </div>
            </div>

            <form className="mt-4">
             
           
              
               

              <Button onClick={()=>navigate("/cart")} color="success" variant='contained' sx={{px:"2rem",py:"1rem",bgcolor:"#9155fd"}}>
                Add To Cart
              </Button>
            </form>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{product.description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {product.highlights.map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{product.details}</p>
              </div>
            </div>
          </div>
        </div> 

        </section>
{/* rating and reviwes is here  is in 3hur 39min see on youthub*/}
 {/* simmler porduct */}


        
      
      <section className='pt-10'>
        <div >
        <h1 className='px-10 text-3xl font-semibold '>Similer Products</h1>
          {Copper_item.map((item)=><Homesection2 products={item}/>)}
        </div>

</section>
</div>
    </div>
  )
}
