import { Button, IconButton } from '@mui/material'
import React from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const Cartitems = () => {
    return (
        <div className='p-5 shadow-lg border rounded-md'>
            <div className='flex items-center'>
                <div className='w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]'>
                    <img src="./copper1.jpg" alt="" className='w-full h-full object-left object-scale-down' />
                </div>
                <div className='ml-5 space-y-1'>
                    <p className='font-semibold'>Best for Beer...</p>
                    <p className='opacity-70 mt-2'>Seller: Copper</p>
                    <div className=' flex space-x-5 items-center  text-gray-900 pt-6'>
                        <p className="font-semibold">Rs.2,090</p>
                        <p className='line-through opacity-50'>499</p>

                        <p className='text-green-600 font-semiblod'>66% Of</p>

                    </div>


                </div>
                
            </div>
            <div className='lg:flex items-center lg:space-x-10 pt-4 '>
                <div className='flex items-center space-x-2'>
                    <IconButton >
                    <RemoveIcon/> 
                    </IconButton>
                    <span className='py-1 px-7 border rounded-sm'> 3 </span>
                    <IconButton sx={{color:"RGB(145,85,256)"}}>
                    <AddIcon/> 
                    </IconButton>
                  
                </div>
                <div>
                    <Button sx={{color:"RGB(145,85,256)"}}>Remove</Button>
                </div>
                </div>

        </div>
    )
}

export default Cartitems
