import React from 'react'

const AddressCart = () => {
  return (
    <div>
      <div className='space-y-3 '>
        <p className='font-semibold'>
          Shubash Dutta
        </p>
        <p>janakpur08,CEC collage</p>
        <div className='space-y-1'>
           <p className='font-semibold'>Phone Number</p>
        <p>98645143</p>
        </div>
       
      </div>
    </div>
  )
}

export default AddressCart
