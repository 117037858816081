import React from 'react'

const Homecard2 = ({ products }) => {
  return (
    <div>
        <div className='cursor-pointer flex flex-col item-center bg-white rounded-lg 
    shadow-lg overflow-hidden w-[15rem] mx-3 border '>

      <div className='h-[15rem] w-[20rem]'>
        <img className="object-left object-scale-down w-full h-full space-y-5"
          src={products.image}
          alt="" />

      </div>
      <div className='p-4'></div>
      <h3 className='text-lg  font-medium text-gray-900'>{products.brand}</h3>
      <p className='mt-2 text-sm text-gary-500'>{products.title}
      </p>
    </div>
    </div>
  )
}

export default Homecard2
