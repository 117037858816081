export const Copper_item = [
    {
        "image": "./Copper1.jpg",
        "brand": "Copper",
        "title": "Copper 2 Brass Designer Beer Glass Tumbler with 2 Jigger Shot Glasses, Best for Beer...",
        "discountprice": "499",
        "price": "Rs.2,090",
        "discountpercentage": "66",
        
    },
    {
        "image": "./copper10.jpg",
        "brand": " mouse",
        "title": "Copper 2 Plain Mug Cup with 4 Jigger Shot Glasses, Barware Set, Best for Parties,...",
        "discountprice": "99",
        "price": "Rs.2,050",
        "discountpercentage": "66",
    },
    {
        "image": "./copper9.jpg",
        "brand": "i-phone",
        "title": "Copper 2 Wine Glass Tumbler with 4 Shot Jiggers Glass, Barware Set, Best for Parties,...",
        "discountprice": "1199",
        "price": "Rs.2,110",
        "discountpercentage": "66",
    },
    {
        "image": "./copper8.jpg",
        "brand": "local cup set",
        "title": "Hammered Copper 2 Moscow Mule Beer Mug Cups with 1 Tray Platter, Barware Set, For...",
        "discountprice": "9",
        "price": "Rs.2,905",
        "discountpercentage": "6",
    },
    {
        "image": "./copper7.jpg",
        "brand": "local jug set",
        "title": "Hammered Copper 2 Mug Cups with Steel Copper Wine Shaker and 1 Hammered Tray Platter,...",
        "discountprice": "9",
        "price": "Rs.2,995",
        "discountpercentage": "6",
    },
    {
        "image": "./copper6.jpg",
        "brand": "home made",
        "title": "Indian Art Villa Copper Tin Mughlai Hammered Design Antique Dark Tone Jug with Brass Handle...",
        "discountprice": "19",
        "price": "Rs.2,880",
        "discountpercentage": "6",
    },
    {
        "image": "./copper5.jpg",
        "brand": "key set",
        "title": "Indian Art Villa Hammered Copper Bowl, Serveware Dinnerware, Hotel Restaurant, Brown",
        "discountprice": "19",
        "price": "Rs.310",
        "discountpercentage": "6",
    },
    {
        "image": "./copper4.jpg",
        "brand": "key-ring for women",
        "title": "Indian Art Villa Hammered Design Antique Dark Tone Copper Jug Pitcher, Storage and Serving Water,...",
        "discountprice": "19",
        "price": "Rs.1,190",
        "discountpercentage": "6",
    },
    {
        "image": "./copper3.jpg",
        "brand": "cup image",
        "title": "Indian Art Villa Printed Flower Design Copper Jug Pitcher, Storage and Serving Water, Yoga Ayurveda...",
        "discountprice": "19",
        "price": "Rs.1,240",
        "discountpercentage": "6",
    },
    {
        "image": "./Cooper2.jpg",
        "brand": "cup image",
        "title": "Indian Art Villa Pure Copper Designer Jug, Pitcher with Brass Knob on Lid, Serveware, Drinkware",
        "discountprice": "19",
        "price": "Rs.950",
        "discountpercentage": "6",
    },
    // {
    //     "imageUrl": "https://media.istockphoto.com/id/1297051332/vector/levitation-laptop-mockup.jpg?s=1024x1024&w=is&k=20&c=7OFuCPAApLoHq_AXYhIR0FEIGp8RF51UKjkRc1YBvHU=",
    //     "brand": "apple-pc",
    //     "title": "Apple-pc",
    //     "discountprice": "2119",
    //     "price": "33399",
    //     "discountpercentage": "6",
    // },
]