import React from 'react'
import Maincarousel from '../comp/Homecarousel/Maincarousel'
import Homesection from '../comp/Homesection/Homesection'
import { electonic_item } from '../../Data/electronic_items'
import { Copper_item } from '../../Data/Copper_items'
import Homesection2 from '../comp/Homesection/Homesection2'

function Homepages() {
  return (
    <div>
      <Maincarousel/>


      <div className='space-y-10 p-y-20 flex flex-col justify-center'>
       <Homesection data={electonic_item} SectionName={"Coper_Item's"}/>
       <Homesection2 data={Copper_item} SectionName={"coperdata"}/>
       <Homesection/>
       
      </div>
    </div>
  )
}

export default Homepages
