import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 // Import the Router component
import Navigation from './customer/comp/Navigation/Navigation';
import Homepages from './customer/pages/Homepages';

import Footer from './customer/comp/Footer/Footer';
import Product from './customer/comp/product/Product';
import Productd from './customer/comp/productd/productd';

import Checkout from './customer/comp/Checkout/Checkout';
import Order from './customer/comp/Orderpage/Order';
import CRouters from './Routers/CRouters';
import Cart from './customer/comp/Cart/Cart';
import OrderD from './customer/comp/Orderpage/OrderD';

function App() {
  return (

    <Router>
      <div>
        <Navigation/>
      </div>
      <Routes>
       
          <Route path='/' element={<Homepages />} />
          

         
          <Route path="/copper" element={<Product/>}></Route>
<Route path="/productDetial" element={<Productd/>}></Route>
<Route path='/Cart' element={<Cart/>}/>
<Route path='/checkout' element={<Checkout/>}/>
<Route path='/Account/order' element={<Order/>}/>
<Route path='/order/detial' element={<OrderD/>}/>

         
     
      </Routes>
      <div>
        
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
