import React from 'react'
import AddressCart from '../AddressCart/AddressCart'
import OrderTracking from './OrderTracking'
import { Box, Grid } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import StarBorderIcon from '@mui/icons-material/StarBorder';


const OrderD = () => {
  return (
    <div className=' px-5 lg:px-20'>
        <div>
             <h1 className=' font-semibold text-xl py-7'>Devlery Address </h1>
        <AddressCart/>
        </div>
      <div className='py-20'>
        <OrderTracking activeStep={3} />
      </div>
      <Grid container className=' flex items-center space-x-4'>
        <Grid item container className='shadow-xl rounded-md p-5 border 'sx={{alignItems:"center",justifyContent:"space-between"}}>
            <Grid item xs={6}>
                <div>
                    <img className='w-[5rem] h-[5rem] object-cover ' src="./copper1.jpg" alt="" />
                    <div className='space-y-2 ml-5' >
                    <span className='space-x-5'>Copper-Glass</span>
                        <p>price</p>
                    </div>
                </div>
            </Grid>
<Grid item>
<Box sx={{color:deepPurple[500]}}>
 <StarBorderIcon sx={{fontSize:"3rem"}} className='px-2'> 
 </StarBorderIcon> 
 <span> Ratting & Review Product </span>
   

</Box>
</Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderD
