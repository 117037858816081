import { Grid } from '@mui/material'
import React from 'react'
import Ordercart from './Ordercart'

const OrderStatus =[
    {lable:"On the way",Value:"On_the_way"},
    {lable:"Delivery",Value:"Delivery"},
    {lable:"Returned",Value:"Returned"},
    {lable:"cancelled",Value:"cancelled"},
]

const Order = () => {
  return (
    <div className='lg:px-20 px-5'>
      <Grid container spacing={0} sx={{justifyContent:"space-between"}}>
        <Grid item xs={2.5}>
            <div className='h-auto shadow-lg bg-white p-5 sticky top-5'>
                <h1 className='font-bold text-lg'>Filter</h1>
                <div className='space-y-4 mt-10'>
                    <h1 className='font-semibold uppercase '>Order status</h1>
                    {OrderStatus.map((option)=><div className='flex items-center'>
                        <input defaultValue={option.Value} type="checkbox" className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label className='ml-3 text-sm' htmlFor={option.Value}>{option.lable}</label>
                    </div>)}
                </div>
            </div>
        </Grid>
        <Grid item xs={9}>
            <Ordercart/>
        </Grid>
      </Grid>
    </div>
  )
}

export default Order
