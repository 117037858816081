import React from 'react'
import Cartitems from './Cartitems'
import { Button, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const navigate =useNavigate();
    return (
        <div>
            <div className='lg:grid grid-cols-3 lg:px-16 relative'>
                <div className='col-span-2'>
                    <Cartitems />
                </div>
                <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>
                    <div className='border'>
                        <p className='uppercase font-bold opacity-60 pb-4'> price details</p>
                        <Divider />
                        <div className='space-y-3 font-semibold mb-10'>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>price</span>
                                <span className='text-green-600'>Rs.2,090</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>Discount</span>
                                <span className='text-green-600'>499</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>delivery charge</span>
                                <span className='text-green-600'>free</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>Total Amount</span>
                                <span className='text-green-600'>Rs.2,090</span>

                            </div>


                        </div>
                        
              <Button onClick={()=>navigate("/checkout")}  className='w-full ' color="success" variant='contained'  sx={{px:"2rem",py:"1rem",bgcolor:"#9155fd"}}>
               Check Out
              </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Cart
