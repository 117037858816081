import React from 'react'
import AddressCart from '../AddressCart/AddressCart'
import Cartitems from '../Cart/Cartitems'
import { Button, Divider } from '@mui/material'

const Ordersummery = () => {
  return (
    <div>
      <div className='p-5 shadow-lg rounded-md border'>
        <AddressCart/>
      </div>
      <div className='lg:grid grid-cols-3 mt-5 relative'>
                <div className='col-span-2'>
                    <Cartitems />
                </div>
                <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>
                    <div className='border'>
                        <p className='uppercase font-bold opacity-60 pb-4'> price details</p>
                        <Divider />
                        <div className='space-y-3 font-semibold mb-10 '>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>price</span>
                                <span className='text-green-600'>Rs.2,090</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>Discount</span>
                                <span className='text-green-600'>499</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>delivery charge</span>
                                <span className='text-green-600'>free</span>

                            </div>
                            <div className='flex justify-between pt-3 text-black'>
                                <span>Total Amount</span>
                                <span className='text-green-600'>Rs.2,090</span>

                            </div>


                        </div>
                        
              <Button  className='w-full ' color="success" variant='contained'  sx={{px:"2rem",py:"1rem",bgcolor:"#9155fd"}}>
               Check Out
              </Button>
                    </div>
                </div>
            </div>
      
    </div>
  )
}

export default Ordersummery
