export const electonic_item = [
    {
        "imageUrl": "./IMG-20231107-WA0010.jpg",
        "brand": "yoyo",
        "title": "white carea",
        "discountprice": "499",
        "price": "1699",
        "discountpercentage": "66",
    },
    {
        "imageUrl": "./IMG-20231107-WA0011.jpg",
        "brand": "yoyo mouse",
        "title": "best wireless mouse",
        "discountprice": "99",
        "price": "499",
        "discountpercentage": "66",
    },
    {
        "imageUrl": "./IMG-20231107-WA0014.jpg",
        "brand": "i-phone",
        "title": "i-phone with 6gb/2586gb",
        "discountprice": "1199",
        "price": "14499",
        "discountpercentage": "66",
    },
    {
        "imageUrl": "./IMG-20231107-WA0021.jpg",
        "brand": "local cup set",
        "title": "cup set ",
        "discountprice": "9",
        "price": "99",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0014.jpg",
        "brand": "local jug set",
        "title": "jug set ",
        "discountprice": "9",
        "price": "199",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0015.jpg",
        "brand": "home made",
        "title": "home ",
        "discountprice": "19",
        "price": "299",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0017.jpg",
        "brand": "key set",
        "title": "key ring",
        "discountprice": "19",
        "price": "99",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0018.jpg",
        "brand": "key-ring for women",
        "title": "key-ring for women",
        "discountprice": "19",
        "price": "399",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0019.jpg",
        "brand": "cup image",
        "title": "cup image",
        "discountprice": "19",
        "price": "399",
        "discountpercentage": "6",
    },
    {
        "imageUrl": "./IMG-20231107-WA0021.jpg",
        "brand": "cup image",
        "title": "cup  set image",
        "discountprice": "19",
        "price": "399",
        "discountpercentage": "6",
    },
    // {
    //     "imageUrl": "https://media.istockphoto.com/id/1297051332/vector/levitation-laptop-mockup.jpg?s=1024x1024&w=is&k=20&c=7OFuCPAApLoHq_AXYhIR0FEIGp8RF51UKjkRc1YBvHU=",
    //     "brand": "apple-pc",
    //     "title": "Apple-pc",
    //     "discountprice": "2119",
    //     "price": "33399",
    //     "discountpercentage": "6",
    // },
]