import React from 'react'

function Homecard({ product }) {
  return (
    <div className='cursor-pointer flex flex-col item-center bg-white rounded-lg 
    shadow-lg overflow-hidden w-[15rem] mx-3 border '>

      <div className='h-[15rem] w-[20rem] space-y-5'>
        <img className="object-cover object-top w-full h-full"
          src={product.imageUrl}
          alt="" />

      </div>
      <div className='p-4'></div>
      <h3 className='text-lg  font-medium text-gray-900'>{product.brand}</h3>
      <p className='mt-2 text-sm text-gary-500'>{product.titel}
      </p>
    </div>
  )
}

export default Homecard
