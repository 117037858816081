 export const filters = [
    {
      id: 'Copper',
      name: 'Copper_Items',
      options: [
        { value: 'Copper', label: 'copper_glass'},
        { value: 'copper-drinkware-jugs', label: 'copper-drinkware-jugs' },
        { value: 'copper-drinkware-tumblers', label: 'copper-drinkware-tumblers' },
        { value: 'copper-drinkware-water-pots', label: 'copper-drinkware-water-pots' },
        { value: 'copper-bedroom-bottle-jar', label: 'copper-bedroom-bottle-jar' },
        { value: 'drink-ware-gift-set', label: 'drink-ware-gift-set' },
      ],
    },
  ]


export const singleFilter = [
    {
        id: "price",
        name: "Price",
        options: [
            { value: "159-399", label: "$159 To $399" },
            { value: "399-999", label: "$399 To $999" },
            { value: "999-1999", label: "$999 To $1999" },
            { value: "1999-2999", label: "$1999 To $2999" },
            { value: "3999-4999", label: "$3999 To $4999" }
        ],
    },
    {
        id: "diccount",
        name: "Discount_range",
        options: [
            { value: "10", label: "10% And Above" },
            { value: "20", label: "20% And Above" },
            { value: "30", label: "30% And Above" },
            { value: "40", label: "40% And Above" },
            { value: "50", label: "50% And Above" },
            { value: "60", label: "60% And Above" },
            { value: "70", label: "70% And Above" },
            { value: "80", label: "80% And Above" }
        ],
    },
    {
        id:"stock",
        name:"Availability",
        options:[
            {value:"in stock",label:"In Stock"},
            {value:"out of stock",label:"Out Of Stock"}
        ]
    }
]