import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Maincarouseldata } from './Maincarouseldata';
// import Navbar from '../Navigation/Navigation';

const Maincarousel = () => {
  const items = Maincarouseldata.map((item, index) => (
    <div key={index} className="single-carousel-item">
      <img
        className='cursor-pointer'
        src={item.image}
        alt={`Image ${index + 1}`}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </div>
  ));

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  return (
    <>
    
      <AliceCarousel
        items={items}
        responsive={responsive}
        disableButtonsControls
        autoPlay
        autoPlayInterval={1000} // Adjust the autoplay interval as needed
        infinite
        disableDotsControls
      />
    </>
  );
};

export default Maincarousel;
